<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>DB Code</label>
            <InputText v-model="item.db_code" :class="{ 'p-invalid': errorEdit.db_code }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.db_code" >{{ errorEdit.db_code[0] }}</small >
        </div>
         <div class="field">
            <label>DB Name</label>
            <InputText v-model="item.db_name" :class="{ 'p-invalid': errorEdit.db_name }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.db_name" >{{ errorEdit.db_name[0] }}</small >
        </div>
         <div class="field">
            <label>DB Phone</label>
            <InputText v-model="item.db_phone" :class="{ 'p-invalid': errorEdit.db_phone }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.db_phone" >{{ errorEdit.db_phone[0] }}</small >
        </div>
         <div class="field">
            <label>DB Email</label>
            <InputText v-model="item.db_email" :class="{ 'p-invalid': errorEdit.db_email }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.db_email" >{{ errorEdit.db_email[0] }}</small >
        </div>
         <div class="field">
            <label>DB Address</label>
            <InputText v-model="item.db_address" :class="{ 'p-invalid': errorEdit.db_address }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.db_address" >{{ errorEdit.db_address[0] }}</small >
        </div>
         <div class="field">
            <label>DB Latitude</label>
            <InputText v-model="item.lat" :class="{ 'p-invalid': errorEdit.lat }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.lat" >{{ errorEdit.lat[0] }}</small >
        </div>
         <div class="field">
            <label>DB Longitude</label>
            <InputText v-model="item.lng" :class="{ 'p-invalid': errorEdit.lng }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.lng" >{{ errorEdit.lng[0] }}</small >
        </div>
        <div class="field">
            <label>DB City</label>
            <Dropdown dataKey="label" 
                    ref="dcity" v-model="item.city_id" 
                    :loading="loadingDropdownCity" 
                    :options="dataDropdownCity" 
                    :class="{ 'p-invalid': errorEdit.city_id }" 
                    optionLabel="label" optionValue="city_id" 
                    placeholder="Select City" :filter="true" 
                    :showClear="true" @filter="searchDropdownCity($event,'edit')"
            />
            <small class="p-invalid" style="color: red" v-if="errorEdit.new_city_id || errorEdit.old_city_id" >The City field is Invalid/Already exists</small>
        </div>
         <div class="field">
            <label>Distributor Status</label>
            <Dropdown v-model="item.db_status" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Select Status" :class="{ 'p-invalid': errorEdit.db_status }"/>
                <small class="p-invalid" style="color: red" v-if="errorEdit.db_status" >{{ errorEdit.db_status[0] }}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownCity: false,

            // dataDropdown
            dataDropdownCity: null,
            dataDropdownStatus: [
                {name: 'ACTIVE', code: 'y'},
                {name: 'NON ACTIVE', code: 'n'},
            ],
            
            old_city_id: null,

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.searchDropdownCity('', 'edit', this.item.territory_city);
                        this.old_city_id = this.item.city_id;
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        searchDropdownCity(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.dcity.filterValue = valueEdit;
                }

                if(purpose == "edit"){
                    this.loadingDropdownCity = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-city3',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "edit"){
                        this.dataDropdownCity = res.data.data;
                        this.loadingDropdownCity = false;
                    }else if(purpose == null){
                        this.dataDropdownCity = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/distributor/update',
                data: {
                    "db_id": this.item.db_id,
                    "db_code": this.item.db_code,
                    "db_name": this.item.db_name,
                    "db_email": this.item.db_email,
                    "db_phone": this.item.db_phone,
                    "db_address": this.item.db_address,
                    "lat": this.item.lat,
                    "lng": this.item.lng,
                    "old_city_id" : this.old_city_id,
                    "new_city_id" : this.old_city_id !== this.item.city_id ? this.item.city_id : null,
                    "status" : this.item.db_status,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
		},
    }
}
</script>