<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>DB Code</label>
                <InputText v-model="forms.db_code" required="true" autofocus :class="{ 'p-invalid': errorAdd.db_code }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.db_code" >{{ errorAdd.db_code[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>DB Name</label>
                <InputText v-model="forms.db_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.db_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.db_name" >{{ errorAdd.db_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>DB Phone</label>
                <InputText v-model="forms.db_phone" required="true" autofocus :class="{ 'p-invalid': errorAdd.db_phone }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.db_phone" >{{ errorAdd.db_phone[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>DP Email</label>
                <InputText v-model="forms.db_email" required="true" autofocus :class="{ 'p-invalid': errorAdd.db_email }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.db_email" >{{ errorAdd.db_email[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>DB Address</label>
                <InputText v-model="forms.db_address" required="true" autofocus :class="{ 'p-invalid': errorAdd.db_address }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.db_address" >{{ errorAdd.db_address[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>DB Latitude</label>
                <InputText v-model="forms.lat" required="true" autofocus :class="{ 'p-invalid': errorAdd.lat }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.lat" >{{ errorAdd.lat[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>DB Longitude</label>
                <InputText v-model="forms.lng" required="true" autofocus :class="{ 'p-invalid': errorAdd.lng }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.lng" >{{ errorAdd.lng[0] }}</small >
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>DB City</label>
                <!-- <div class="field-checkbox mt-2">
                    <Checkbox id="isAllOutlet" v-model="checked" :binary="true" :disabled="forms.area_id == null" @change="this.forms.outlet_id = [];"/>
                    <label for="isAllOutlet">Centang jika pilih semua Outlet</label>
                </div> -->
                <Listbox ref="doutletcity" v-model="forms.city_id" :options="dataDropdownOutletCity" :class="{ 'p-invalid': errorAdd.city_id }" :multiple="true" :filter="true" optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownOutletCity($event, 'add')">
                    <template #option="slotProps">
                        <div>{{slotProps.option.label}}</div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.city_id">{{
                    errorAdd.city_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-9" v-if="convertMultiSelectOutletCity(forms.city_id).length != 0">
                <div class="card" style="height: calc(60vh - 143px)">
                    <DataTable :value="forms.city_id" :scrollable="true" scrollHeight="flex">
                        <Column field="label" header="Outlet City">
                            <template #body="slotProps">
                                {{ slotProps.data.label }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownOutletCity: false,

            // dataDropdown
            dataDropdownOutletCity: null,

            // addNew
            forms: {
                db_code: null,
                db_name: null,
                db_phone: null,
                db_email: null,
                db_address: null,
                lat: null,
                lng: null,
                city_id: [],
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownOutletCity('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        convertMultiSelectOutletCity(data) {
            if(data){
                return data.map((item) => {
                    return item.city_id;
                });
            }else{
                return [];
            }
        },
        // DROPDOWN
        searchDropdownOutletCity(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.doutletcity.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownOutletCity = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-city3',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownOutletCity = res.data.data;
                        this.loadingDropdownOutletCity = false;
                    } else if (purpose == null) {
                        this.dataDropdownOutletCity = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/distributor/create',
                data: {
                    "db_code": this.forms.db_code,
                    "db_name": this.forms.db_name,
                    "db_email": this.forms.db_email,
                    "db_phone": this.forms.db_phone,
                    "db_address": this.forms.db_address,
                    "lat": this.forms.lat,
                    "lng": this.forms.lng,
                    "city_id": this.convertMultiSelectOutletCity(this.forms.city_id),
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.db_code = null;
            this.forms.db_name = null;
            this.forms.db_email = null;
            this.forms.db_phone = null;
            this.forms.db_address = null;
            this.forms.lat = null;
            this.forms.lng = null;
            this.forms.city_id = [];
        },
    }
}
</script>